.top-navbar{
    position: absolute;
    left: 230px;
    top: 0px;
    width: 1290px;
    height: 80px;
    border: 1px solid #E5E5E5;
}
.nav-icon{
    position: absolute;
    top: 28%;
    left: 5%;
    color: #861F41;
}
.navbar-brand{
    position: absolute;
    top: 25%;
    left: 8%;
    color: #424242;
    font-size: 22px;
    line-height: 27px;
}
.input-group{
    width: 300px;
    height: 40px;
    border: 1px solid #424242;
    position: absolute;
    top: 22%;
    left: 55%;
    border-radius: 30px;
}
.search-here{
    border: none;
    border-radius: 30px;
    margin-left: 0rem;
}
.search-icon{
   position: absolute;
   left: 80%;
   top: 20%;
   color: #090909;
}
.fa-bell{
    color: #424242;
    position: absolute;
    left: 80%;
    top: 28%;
}
.fa-circle{
    color: #707070;
    position: absolute;
    left: 84%;
    top: 28%;
}
.user-name{
    position: absolute;
    top: 28%;
    left: 87%;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    color: #707070;
}
