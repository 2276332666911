.create-user-card{
    width: 1100px;
    height: 650px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: absolute;
    left: 5%;
    top: 12%;
}
.business-detail{
    position: relative;
    top: 3%;
}
.bank-detail{
    position: relative;
    top: 0%;
}
h3{
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.business-details{
    display: flex;
    flex-direction: row;
}
.business-text{
    width: 200px;
    color: #2C2C2E;
    font-size: 16px;
    font-weight: 500;
}
.business-input{
    width: 350px;
    border: none;
    border-bottom: 2px solid #2C2C2E;
}
.verification{
    color: #A8A8A8;
    font-size: 14px;
}
.file-upload{
    position: absolute;
    left: 80%;
    top: 10%;
    width: 150px;
    height: 150px;
    background: #E5E5E5;
    border: 2px dotted gray;
    border-radius: 5px;
    font-size: 14px;
}