.display-page{
    position: absolute;
    left: 230px;
    top: 80px;
    background: #E5E5E5;
    width: 1290px;
    height: 830px;
}
.dashboard-navbar{
    width: 1290px;
    height: 80px;
    background: #ffffff;
    border: 1px solid #E5E5E5;
}
.dash-icon{
    position: absolute;
    top: 4%;
    left: 5%;
}
.dashboard-nav{
    text-decoration: none;
    font-size: 20px;
    color: #424242;
    font-weight: 500;
    line-height: 27px;
    position: absolute;
    left: 8%;
    top: 4%;
}
.btn-danger{
    position: absolute;
    top: 3%;
    left: 80%;
}
.first-row{
    position: relative;
    top: 5%;
}
.cash-flow-card{
    width: 700px;
    height: 300px;
    position: absolute;
    left: 5%;
}
.transaction-card{
    width: 300px;
    height: 300px;
    position: absolute;
    left: 65%;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
li{
    list-style-type: none;
}
.store-card{
    width: 250px;
    height: 100px;
    margin-left: -2rem;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
}
.store-heading{
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-top: -0.5rem;
}
.column-store{
    display: flex;
    flex-direction: column;
}
.deposit-text{
    font-size: 14px;
}
.balance{
    font-size: 14px;
}
.more{
    color: orangered;
    text-decoration: none;
    margin-left: 10rem;
    margin-top: 2rem;
}
.second-row{
    position: relative;
    top: 45%;
}
.deposit-card{
    width: 280px;
    height: 330px;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    left: 5%;
}
.stores-card{
    width: 250px;
    height: 125px;
    margin-left: -2rem;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
}
.store-info{
    display: flex;
    flex-direction: row;
    margin-top: -1rem;
}
.store-infos{
    display: flex;
    flex-direction: column;
}
.store-name{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.store-balance{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.withdraw-card{
    width: 280px;
    height: 330px;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    left: 28%;
}
.advance-card{
    width: 280px;
    height: 330px;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    left: 52%;
}
.user-card{
    width: 280px;
    height: 330px;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    left: 77%;
}